

























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BankAccountDTO from "@/dto/BankAccountDTO";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {BankAccountType} from "@/dto/BankAccountType";
import {HolderType} from "@/dto/HolderType";
import {STATE_LIST} from "@/constants/ProfileConstants";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {ifValid, validate} from "@/utils/ComponentUtils";
import PortalInput from "@/components/common/PortalInput.vue";

const AppModule = namespace("App");

@Component({
	computed: {
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {PortalInput, AutoComplete}
})
export default class CreationBankAccountListModal extends Vue {
	private bankAccount: BankAccountDTO = new BankAccountDTO();

	private message = "";

	private states: Array<string> = STATE_LIST;

	private successful = true;

	private workspace: Workspace = Workspaces.getCurrent as Workspace;

	@AppModule.Action
	private startLoading!: () => void;

	@AppModule.Action
	private stopLoading!: () => void;

	@Prop()
	private onSuccess!: () => void

	public created(): void {
        this.bankAccount.accountHolder = this.workspace.name;
		this.bankAccount.accountType = BankAccountType.CHECKING;
		this.bankAccount.holderType = HolderType.BUSINESS;
	}

	private accountNumber(str: string) {
		if (str.length >= 9 && this.bankAccount.accountNumber !== null) {
			this.bankAccount.accountNumber = this.bankAccount.accountNumber.trim().substr(0, 9);
		}
	}

	private routingNumber(str: string) {
		if (str.length >= 9 && this.bankAccount.routingNumber !== null) {
			this.bankAccount.routingNumber = this.bankAccount.routingNumber.trim().substr(0, 9);
		}
	}

	private closeModal(){
		this.$modal.hideAll();
	}

	private save(): void {
		ifValid(this, () => {
			this.message = '';
			this.startLoading()
			if (this.bankAccount.state != null) {
				this.bankAccount.state = this.bankAccount.state.toUpperCase();
			}
			switch (this.workspace.type) {
				case WorkspaceType.COMPANY: {
					this.saveCompanyBankAccount()
					break;
				}
				case WorkspaceType.PERSON:{
					this.savePersonBankAccount()
					break;
				}
			}
		})
	}

	private processResp(): void {
		this.successful = true;
		this.stopLoading();
		this.$modal.hideAll();
		this.onSuccess();
	}

	private processError(err: any) {
		this.successful = false;
		let param = err?.response?.data.param;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), {param: param}) as string;
		this.stopLoading();
	}

	private saveCompanyBankAccount() {
		this.bankAccount.companyId = this.workspace.id;
		CompanyService.addBankAccount(this.bankAccount).then(
			ok => this.processResp(),
			err => this.processError(err)
		)
	}

	private savePersonBankAccount() {
		this.bankAccount.personId = this.workspace.id;
		PersonService.addBankAccount(this.bankAccount).then(
			ok => this.processResp(),
			err => this.processError(err)
		)
	}
}
